<template>
  <div class="full-height d-flex-column">
    <div class="flex-1 main">
      <h3 class="align-text-center">A verification code has been sent to:</h3>

      <h3 class="align-text-center">
        {{ sentTo }}
      </h3>

      <h3 class="align-text-center">Please provide the code below to create your account.</h3>

      <div class="align-text-center">
        <input type="number" class="full-code" v-model="code" />
      </div>

      <div class="mw-400">
        <el-button
          type="primary"
          class="full-width"
          @click="verifyCode"
          :disabled="code.length !== 4"
          ref="verify"
        >
          Verify
        </el-button>
      </div>
      <div v-if="error" class="error-msg">
        {{ error }}
      </div>
    </div>

    <el-card class="mt-2 flex-0">
      <div class="mw-500">
        <div class="flex-1 align-text-center m-1">Didn't receive the message?</div>

        <el-button @click="handleResend" type="success" class="full-width button-light button-thin">
          Resend message
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import store from "@/store";
import msg from "@/utils/constants/msg";

export default {
  name: "VerificationCode",
  data() {
    return {
      code: "",
      error: null,
      fail: 0, // fail count
    };
  },
  beforeRouteEnter(to, from, next) {
    // navigation guard for `verify code`

    // code has not been sent => go back 1 step
    if (!store.state.verify.codeSent) {
      next({ name: "verification" });
      return;
    }

    // if user is not verified => verifying user itself => dont do nothing
    if (store.getters["verify/userRequiresVerification"]) {
      next();
      return;
    }

    // if verification object is not stored (do not use persistent storage on this one)
    // go to loginRedirect if exists or regotype
    if (!store.state.verify.linkProfileVerificationObj.id)
      next({ name: this.loginRedirect || "regotype" });
    store.dispatch("views/clearLoginRedirect");

    next();
  },
  methods: {
    ...mapActions("verify", ["verify2"]),
    ...mapMutations("verify", ["CODE_SENT"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),
    handleResend() {
      // clear code sent state
      this.CODE_SENT(false);
      // go back
      this.$router.push({ name: "verification" }).catch(() => {});
    },
    verifyCode() {
      const snapShot = JSON.stringify(this.current);
      const rootUrl = this.$store.state.privateLink.rootUrl;
      const current = JSON.parse(snapShot);
      this.verify2({
        vm: this,
        code: this.code,
        current,
        rootUrl,
      }).then((success) => {
        // success redirection is handled via store action, where fail is handled locally

        if (success) this.$router.push({ path: '/register' }).catch(() => {});
        if (!success) this.fail += 1;
        if (this.fail >= 3) this.fail3();
      });
    },
    fail3() {
      this.CODE_SENT(false);
      this.PUSH_NOTIFICATION({
        msg: msg.error.verification.fail3,
        route: "verification",
        type: "warning",
      });

      this.$router.push({ name: "verification" }).catch(() => {});
    },
  },
  computed: {
    ...mapGetters("verify", ["sentTo", "userRequiresVerification"]),
    ...mapState("verify", ["codeSent", "linkProfileVerificationObj"]),
    ...mapState("registrationSession", ["current"]),
    loginRedirect() {
      return this.$store.getters["views/loginRedirect"];
    },
  },
};
</script>

<style lang="scss" scoped>
.full-code {
  width: 160px;
  height: 40px;
  margin: 8px;
  box-shadow: 0 0 6px 2px rgba(200, 200, 200, 0.3);
  border: none;

  text-align: center;
  font-size: 25px;
}

.square::-webkit-inner-spin-button,
.square::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
